<template>
<!--    <ul v-if="dataKeys.length">-->
<!--      <li v-for="(item,index) in dataKeys" :key="item+index">-->
<!--        <img v-if="item ==='headimgurl'" :src="userInfo.headimgurl" />-->
<!--        <span v-else>{{userInfo[item]}}</span>-->
<!--      </li>-->
<!--    </ul>-->
  <div class="home">
      <div v-if="showBaoMing">
        <div class="pic1">
          <img src="@/assets/harmtxjz.png" width="400" height="220" @click="signUp"/>
        </div>
          <!-- <el-button type="primary" @click="signUp">点击报名</el-button> -->
      <div v-if="isPay">
       <el-dialog
          title="家长告知"
          :visible.sync="centerDialogVisible"
          width="90%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          append-to-body
          center>
         <div class="txt">
            <el-text class="bold">2024年度融媒体小记者招募告家长书</el-text><br><br>
            <el-text>
            尊敬的家长:<br>
            您好!<br>
            首先，融媒体小记者团全体工作人员欢迎您的孩子加入我们小记者大家庭。我们会秉承一贯的理念，关注孩子的体验、鼓励孩子写作，
            为小记者搭建更优质的成长平台，提供更多的体验机会。现将会员小记者享有的权益和应该履行的责任作如下告知:
            <br><br>
            <el-text class="bold">一、报名办法	</el-text><br>
                登记小记者基本信息，并缴纳年费(580元)，最终以缴费成功为准。<br><br>     
            <el-text class="bold">二、融媒体小记者六项会员权益</el-text><br>	
                1.小记者专属标识<br>
                  每位会员小记者将拥有一本海安融媒体认证的小记者证。这是这是一个身份证明，也是一份成长记录。<br>	
                2.小记者专属装备<br>	
                  每位会员小记者将获得小记者采访包、采访本、笔等装备。<br>
                3.小记者专属刊物<br>
                  小记者专属刊物每位会员小记者将获得海安市融媒体中心海安日报特别版一份(投递至各学校后派发或自行领取)。<br>	
                4.佳作发表权益<br>
                  小记者的优秀习作、书画作品将在《悦海安》APP推送、在《海安日报》专栏上刊发。小记者的出镜视频择优在《海安发布》视频号推送。<br>
                5.有机会参与融媒体小记者各项活动<br>
                  融媒体中心将组织融媒先锋、优秀记者、主持人开展校园巡讲活动，和小记者面对面，传授媒介素养课，提升小记者的采访、沟通、写作，
                以及语言表达能力等；优先获得融媒体中心主办或承办的少儿主题征文、摄影、融媒星主播评选等赛事的参与权；观看由融媒体中心大剧院策划、
                组织的一场儿童剧；走进新闻录制间，真实体验融媒体中心新闻稿件录制并制作视频在海安融媒体官方平台进行发布等。<br>
                6.各种实践活动<br>
                  会员小记者可优先参与由融媒体中心组织的各种实践活动（具体活动按实际情况收取适当成本费用），在体验中学习采访与写作。<br><br>
            <el-text class="bold">三、注意事项:</el-text><br>
                1.2024年会员小记者会员期限为从注册成功开始至2024年秋季学期放假日止，会员权益将在2024年秋季学期放假日终止。<br>
                2.会员期间，会员小记者均应积极撰稿，主动投稿。如在会员期限内不投稿，将无法享受相应权益，且不接受退款。<br>
                3.请各位家长关注好“海安发布”微信公众号和悦海安APP，小记者各项活动将在这两个平台提前发布，家长和小记者根据自身需求自主选择报名参加。<br>
            </el-text>
         </div>
        <span slot="footer">
          <el-button type="text" @click="doPayJsApi">
            <el-text class="word2">同意</el-text>
          </el-button>
        </span>
       </el-dialog>
      </div>
      </div>
      <div v-if="tLogin" class="homeT">
        <div class="pic">
          <img src="@/assets/Name2.png" width="280" height="120"/>
        </div>
          <el-form
            ref="userForm"
            :model="user"
            :rules="rules"
            status-icon
            label-width="auto"
            class="form"
            style="border-radius:5px"
          >
            <el-form-item label=" " prop="userName">
              <el-input v-model="user.userName" type="text" placeholder="请输入学生姓名" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="password">
              <el-input v-model="user.password"  placeholder="请输入密码" prefix-icon="el-icon-unlock"></el-input>
            </el-form-item>
            <div class="loginButo">
              <el-button class="buto" @click="toLogin" round>
                <el-text class="word">登录</el-text>
              </el-button>
              <el-button type="text" @click="toRe" round>
                <el-text class="word3">还未注册</el-text>
              </el-button> 
            </div>
          </el-form>
      </div>

      <div v-if="isShow" class="homeT">
        <div class="pic">
          <img src="@/assets/Name2.png" width="280" height="120"/>
        </div>
          <el-form
            ref="userForm"
            :model="user"
            :rules="rules"
            status-icon
            label-width="auto"
            class="form"
            style="border-radius:5px"
          >
            <el-form-item label=" " prop="userName">
              <el-input v-model="user.userName" type="text" placeholder="请输入学生姓名" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="password">
              <el-input v-model="user.password"  placeholder="请输入密码" prefix-icon="el-icon-unlock"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="phonenumber" >
              <el-input v-model="user.phonenumber"  placeholder="请输入手机号" prefix-icon="el-icon-phone-outline"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="schoolName">
              <el-input v-model="user.schoolName"  placeholder="请输入学校名" prefix-icon="el-icon-school"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="gradeName">
              <el-input v-model="user.gradeName"  placeholder="请输入年级" prefix-icon="el-icon-position"></el-input>
            </el-form-item>
            <el-form-item label=" " prop="className">
              <el-input v-model="user.className"  placeholder="请输入班级" prefix-icon="el-icon-position"></el-input>
            </el-form-item>
            <el-button class="buto" @click="toRegister" round>
              <el-text class="word">注册并登录</el-text>
            </el-button> 
          </el-form>
      </div>      

      <div v-if="isStu">
        <h3>{{ this.user.userName+'登录成功'}}</h3>
      </div>
      <div class="el-login-footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">备案号:</a>
        <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024059834号-1</a>
      </div>
  </div>
</template>

<script>
import {getJsApi, getWechatInfo} from "@/api/pay";
import wx from 'weixin-jsapi'
import {login, register} from "@/api/user";
import { reactive, ref } from 'vue'

// 公众号信息
let config = {
    appId: "wx7fe18890d920ec7d",
  // appId: "wx8526b4637f4dbb3f",
  redirect_uri: `${location.origin}/openid`, // // 这里要改成你的natapp映射的地址 回调地址，相当重要 80端口（自动省略）
};

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data(){
    var validatePass = (rule, value, callback) => {
      var reg = /^1[3-9]\d{9}$/;
        if (value === '') {
          callback(new Error('请输入手机号'));
        } else if(!reg.test(value)){
          callback(new Error('请正确输入手机号'));
        } else{
          callback();
        }
      };
    return {
      userInfo: null,
      dataKeys: [],
        wechatInfo: null,
        userData: null,
        openId: null,
        test: null,
        user:{
            userName: '',
            password: '',
            phonenumber:'',
            schoolName:'',
            gradeName:'',
            className:''
        },
        loginUser:{
          username:'',
            password:''
        },
        rules: {
          userName:[
            { required: true,trigger: 'blur',message: '请输入姓名',}
          ],
          password:[
            { required: true, trigger: 'blur', message: '请输入密码',}
          ],
          phonenumber:[
            { required: true,validator: validatePass,trigger: 'blur',}
          ]
        },
        tLogin:true,  //登录页面
        isShow: false,  //注册页面
        isStu: false,
        isPay:false,  //缴费页面
        centerDialogVisible:true,
        showBaoMing:false, //报名页面
    }
  },
  methods: {
      signUp() {
          this.showBaoMing = true
          this.isPay = true
      },
      toLogin(){
          this.loginUser.username = this.user.userName
          this.loginUser.password = this.user.password
          login(this.loginUser).then((res) => {
              if(res.data.code==200){
                  //存储userId和token到本地
                  this.$store.commit("setUserId", this.user.userName);
                  this.$store.commit("setToken", res.data.token);
                  this.tLogin = false
                  this.showBaoMing = true
                  //跳转到主页
                  // this.$router.replace('/home');
              }else{
                  alert('用户名或密码错误！');
                  return false;
              }

          })
      },
      toRe(){
          this.tLogin = false
          this.isShow = true
      },
      toRegister(){
          register(this.user).then((re)=>{
              if (re.data.msg){
                  console.log("注册-成功--");
                  this.tLogin = false
                  this.isShow = false
                  this.isPay=true
                  this.isStu = true
                  this.showBaoMing= true
              }else {
                  alert(re.data.msg);
                  this.tLogin = true
                  this.isShow = false
                  this.isPay=false
                  this.isStu = false
                  // this.user.userName = ''
                  // this.user.password = ''
                  // this.user.phonenumber = ''
                  // this.user.sex = ''
                  // this.user.schoolName = ''
                  // this.user.gradeName = ''
                  // this.user.className = ''
              }
          })
      },
      //获取openid
      getUerInfo(code) {
          getWechatInfo({
              code: code
          }).then((re)=>{
              this.openId = re.data.openid
              this.user.nickName = re.data.nickname
              console.log("getWechat-成功--", re);
          })
      },
      doPayJsApi(){
          let This = this;
          // let name = this.$store.state.setUserId
          getJsApi({
              openId: this.openId,
              username: this.user.userName
          }).then((re)=>{
              this.userData = re.data
              wx.config({
                  debug: false,					//开启调试模式
                  appId: re.data.appId,			//公众号的唯一标识
                  timestamp: re.data.timeStamp,	//生成签名的时间戳
                  nonceStr: re.data.nonceStr,		//生成签名的随机串
                  signature: re.data.signature,	//签名
                  jsApiList: [ 'chooseWXPay']		//需要使用的JS接口列表
                  //openTagList : ['wx-open-launch-weapp']
              })
              wx.ready(function () {
                  wx.chooseWXPay({
                      timestamp: re.data.timeStamp,	// 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                      nonceStr: re.data.nonceStr,	// 支付签名随机串，不长于 32 位
                      package: re.data.package,	// 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                      signType: re.data.signType,	// 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                      paySign: re.data.paySign,		// 支付签名
                      success: function (res) {
                          window.location.href = '支付成功页面'
                      },
                  });
              })
              console.log("根据openid获取预支付id-成功--", this.userData)

          })
      },
    getUserInfoByCode(code) {
      return new Promise((resolve, reject) => {
        let url = "/api/wx?code=" + code;
        $.ajax({
          type: "GET",
          url,
          cache: false,
          async: true,
          beforeSend: function () {
            //等待延迟的函数
          },
          dataType: "json",
          // dataType: ($.browser.msie) ? "text" : "xml",
          success: function (data) {
            console.log("getQrCode-成功--", data);
            resolve(data);
          },
          fail: function (err) {
            console.log("getQrCode-err--", err);
            reject(err);
          },
        });
      });
    },
    // 获取url的参数
    getUrlParams() {
      let str = window.location.search;
      console.log("str", str);
      // ?code=051YCH000vmCDN1EmM200Bdl0f3YCH0w:77 str ?
      if (str && str.includes("?") && str.includes("=")) {
        let obj = {};
        str = str.slice(1);
        if (str.includes("&")) {
          let arr = str.split("&");
          console.log('arr:',arr);
          for (let i = 0; i < arr.length; i++) {
            let one = arr[i].split("=");
            obj[one[0]] = one[1];
          }
        } else {
          let one = str.split("=");
          obj[one[0]] = one[1];
        }
        return obj;
      }
    },
    getUserUrl(userId){
      let url = `${config.redirect_uri}/?userId=${userId}`
      return url;
    },
    // 获取url
    getWxUrl(){
      // 生成带有分享人id的二维码
      let backUrl = encodeURIComponent(config.redirect_uri);
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appId}&redirect_uri=${backUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      return url;
    },
    async getInfo() {
      // 是否为回调页面
      let paramsObj = this.getUrlParams();
      console.log("paramsObj", paramsObj);
      if(!paramsObj || !paramsObj.code){
        
        // 1.第一次进入这个页面。初次页面，需要调起授权页面
        let url = this.getWxUrl()
        window.location.href = url;
      }
      else {
        // 2.第二次进入这个页面，是微信服务器回调回来的
        // 微信服务器给我们传了code，我们将code传给我们的后台，去获取用户信息
        try {
          let result = await this.getUserInfoByCode(paramsObj.code);
          console.log("result:", result);
          const { headimgurl, nickname ,openid } = result;
          this.userInfo = result;
          this.dataKeys = []
          if(result['openid']){
            this.dataKeys = ['headimgurl', 'nickname' ,'openid']
            alert("成功获取openid");
            const domain = localStorage.getItem('domain')
            let otherUser = { headimgurl, nickname ,openid, domain };
            localStorage.setItem('otherUser', JSON.stringify(otherUser))
            this.$router.push('/')
          }
        } catch (error) {
          console.error("服务器报错：error:", error);
          alert("服务器出错");
        }
      }
    },
    isLogin(){
      let paramsObj = this.getUrlParams();
        console.log("paramsObj入:", paramsObj.code);
        this.getUerInfo(paramsObj.code)
        console.log("paramsObj出:", paramsObj.code)
      // let domain = localStorage.getItem('domain');
      // if(paramsObj && paramsObj.userId){
      //   alert(`带了参数进来：${paramsObj.userId}`)
      //   this.currentUserId = paramsObj.userId;
      //   localStorage.setItem('domain', paramsObj.userId)
      // } else if(domain){
      //   this.currentUserId = domain;
      // } else {
      //   this.$router.push('/login')
      // }
      
    }
  },
  mounted() {
    this.isLogin();

    if(!this.currentUserId){
      return
    }
    this.$nextTick(()=>{
      // 拉起授权页面
      this.getInfo();

      // 生成带参二维码
      // this.bindQRCode();
    })

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.txt{
  height: 350px;
  overflow: auto;
}
/*图片*/
.pic{
  position: absolute;
  top: 0%;
  left: 50%;
  transform:translate(-50%,-50%);
}
.pic1{
  position: absolute;
  top: 30%;
  left: 50%;
  transform:translate(-50%,-50%);
}
/*大框架*/
.home{
  position:fixed;  /*固定定位位置*/
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  margin:0%;
  background: url("@/assets/bg-01.jpg") no-repeat center top;   /*背景样式*/
  background-size: 100% 100%;   /*自适应屏幕大小平铺*/
  overflow-y: auto;
}
/*小框架*/
.homeT{
  position: absolute;
  top:55%;
  left: 50%;
  transform:translate(-50%,-50%);
}
/*表单*/
.form{
  border-radius: 10px;
  background: url("@/assets/2.png") no-repeat center top;
  background-size: 100% 100%;
  /* background: #ffffff; */
  width: 350px;
  padding: 100px 50px 100px 50px;   /*内边距上右下左*/ 
  
}
.label{
  color: red;
}
/*按钮*/
.buto{
  width: 150px;
  height: 40px;
  background: url("@/assets/bg-02.png") no-repeat;
  background-size: 100% 100%;
  opacity: 0.83;
}
.loginButo{
  margin-top: 25%;      /*外边距*/
  margin-bottom: -25%;
}
/*按钮字体*/
.word{
  font-size: 20px;
  font-family:'Courier New', Courier, monospace;
  color: black;
  font-weight:bold;
}
.word2{
  font-size: 19px;
}
.word3{
  text-decoration: underline;
}
/*文本字体加粗*/
.bold{
  font-weight:bold;
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>
