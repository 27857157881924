import request from "@/utils/request";

export function register(data) {
    return request({
        method: 'post',
        url: `/stu/register`,
        data: data
    })
}

export function login(data) {
    return request({
        method: 'post',
        url: `/login`,
        data: data
    })
}

export default class user {
}