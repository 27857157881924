<template>
    <div>
        <button @click="getInfo">立即支付</button>
        <p>{{this.userInfo}}</p>
    </div>
</template>

<script>
import {getWechat,getWechatInfo} from "@/api/pay";

export default {
    name: "PayMent",
    data(){
        return {
            userInfo:null,
            userName: 'ry',
            code: '0419y9Ha1POIYG0dIEIa11cY8A29y9He'
        }
    },
    methods: {
        doPay() {
            getWechat(this.userName).then((re)=>{
                this.userInfo = re.data.data
            })
        },
        getInfo() {
            getWechatInfo(this.code).then((re) => {
                this.userInfo = re.data
            })
        }
    }
}
</script>

<style scoped>

</style>