import axios from "axios";

const request = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    baseURL: process.env.VUE_APP_BASE_API,
    timeout:5000,
})

export default request