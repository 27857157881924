import request from "@/utils/request";

export function getWechat(userName) {
    return request({
        method: 'GET',
        url: `/test/info/${userName}`,
    })
}

export function getWechatInfo(params) {
    return request({
        method: 'GET',
        url: `/wx`,
        params:params,
    })
}

export function getJsApi(params) {
    return request({
        method: 'GET',
        url: `/v3/jsApiPay`,
        params:params
    })
}

export default class pay {
}