<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <hr />
    <h3>欢迎：{{ currentUserId }}</h3>
    <div>
        <button type="primary" @click="tiaozhuan">跳转</button>
      <h3>
        <button @click="bindQRCode" class="btn">生成带参二维码</button>
      </h3>

      <div v-if="showCode">
        <h3 style="color: orange;">将二维码分享给朋友，赚10元</h3>
        <div id="qrCode" class="qrCode" ref="qrCodeDiv"></div>
      </div>

    </div>
    <div v-if="userInfo">
      <h3>推广人：{{ userInfo.domain }}</h3>
      <p>下线：</p>
      <p>
        {{ userInfo.nickname }}, openid: {{  userInfo.openid }}
      </p>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
// 公众号信息
let config = {
    // appId: "wx8526b4637f4dbb3f",
    appId: "wx7fe18890d920ec7d",
  redirect_uri: `${location.origin}/openid`, // 这里要改成你的natapp映射的地址 回调地址，相当重要 80端口（自动省略）
};
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data(){
    return {
      currentUserId: sessionStorage.getItem('username'),
      userInfo: null,
      dataKeys: [],
      showCode: false,
    }
  },
  methods: {
      tiaozhuan() {
          this.$router.push("/payMent");
      },
    // 生成二维码
    bindQRCode: function () {
      let url = this.getUserUrl(this.currentUserId)
      this.showCode = false;
      this.$nextTick(()=>{
        this.showCode = true;
        this.$nextTick(()=>{
          new QRCode(this.$refs.qrCodeDiv, {
            text: url,
            width: 200,
            height: 200,
            colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
          })
        })
      })
      // 原文链接：https://blog.csdn.net/weixin_50241387/article/details/123549095
    },
    getUserInfoByCode(code) {
      return new Promise((resolve, reject) => {
        let url = "/prod-api/wx?code=" + code;
        $.ajax({
          type: "GET",
          url,
          cache: false,
          async: true,
          beforeSend: function () {
            //等待延迟的函数
          },
          dataType: "json",
          // dataType: ($.browser.msie) ? "text" : "xml",
          success: function (data) {
            console.log("getQrCode-成功--", data);
            resolve(data);
          },
          fail: function (err) {
            console.log("getQrCode-err--", err);
            reject(err);
          },
        });
      });
    },
    // 获取url的参数
    getUrlParams() {
      let str = window.location.search;
      console.log("str", str);
      // ?code=051YCH000vmCDN1EmM200Bdl0f3YCH0w:77 str ?
      if (str && str.includes("?") && str.includes("=")) {
        let obj = {};
        str = str.slice(1);
        if (str.includes("&")) {
          let arr = str.split("&");
          console.log('arr:',arr);
          for (let i = 0; i < arr.length; i++) {
            let one = arr[i].split("=");
            obj[one[0]] = one[1];
          }
        } else {
          let one = str.split("=");
          obj[one[0]] = one[1];
        }
        return obj;
      }
    },
    getUserUrl(userId){
      let url = `${config.redirect_uri}/?userId=${userId}`
      return url;
    },
    // 获取url
    getWxUrl(){
      // 生成带有分享人id的二维码
      let backUrl = encodeURIComponent(config.redirect_uri);
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appId}&redirect_uri=${backUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      return url;
    },
    async getInfo() {
      // 是否为回调页面
      let paramsObj = this.getUrlParams();
      console.log("paramsObj", paramsObj);
      if(!paramsObj || !paramsObj.code){
        
        // 1.第一次进入这个页面。初次页面，需要调起授权页面
        let url = this.getWxUrl()
        window.location.href = url;
      }
      else {
        // 2.第二次进入这个页面，是微信服务器回调回来的
        // 微信服务器给我们传了code，我们将code传给我们的后台，去获取用户信息
        try {
          let result = await this.getUserInfoByCode(paramsObj.code);
          console.log("result:", result);
          const { headimgurl, nickname ,openid } = result;
          this.userInfo = result;
          this.dataKeys = []
          if(result['openid']){
            this.dataKeys = ['headimgurl', 'nickname' ,'openid']
            alert("成功获取openid");
          }
        } catch (error) {
          console.error("服务器报错：error:", error);
          alert("服务器出错");
        }
      }
    },
    isLogin(){
      let paramsObj = this.getUrlParams();
        console.log("paramsObj:", paramsObj);
      this.getInfo();
      let obj = localStorage.getItem('otherUser')
      if(obj){
        obj = JSON.parse(obj)
        this.userInfo = obj;
      } else if(paramsObj && paramsObj.userId){
        alert(`带了参数进来：${paramsObj.userId}`)
        this.currentUserId = paramsObj.userId;
      } else {
        this.$router.push('/openid')
      }
      
    }
  },
  mounted() {
    this.isLogin()
    
    // this.$nextTick(()=>{
    //   // 拉起授权页面
    //   this.getInfo();

    //   // 生成带参二维码
    //   // this.bindQRCode();
    // })

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  *{
    list-style: none;
  }
  .btn{
    background-color: #69f;
    padding: 8px 15px;
    margin: 5px 10px;
    border: none;
    border-radius: 3px;
    color: #fff;
  }
  /deep/ .qrCode img{
    margin: 10px auto;
  }
</style>
