<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" class="logo"> -->

     <HelloWorld msg="微信公众号开发"/>
<!--    <Openid></Openid>-->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Openid from './Openid.vue';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Openid
  }
}
</script>
<style scoped>
.logo{
  position: absolute;
  top: 15px;
  left: 15px;
  width: 80px;
}
</style>