<template>
  <div class="wrapper">
    <div class="login-box">
      <h3>登录页面</h3>
    </div>
  </div>
</template>

<script>
import {register} from "@/api/user";

export default {
  data(){
    return {
      userInfo:{
          userName: '',
        password: '',
          phonenumber:'',
          sex:'',
          nickName: '',
          schoolName:'',
          gradeName:'',
          className:''
      }
    }
  },
  methods:{
      toRegister(){
          register(this.userInfo).then((re)=>{
              console.log("注册-成功--");
              this.$router.push("/payMent")
          })
      // if(this.userInfo.username && this.userInfo.password ){
      //   // sessionStorage.setItem('username',this.userInfo.username)
      //   this.$router.push(`/?userId=${this.userInfo.username}`)
      // } else {
      //   alert('请输入账号和密码登录')
      // }

    },

  },
    mounted() {
      // this.getCode()
    }
}
</script>

<style>
  .wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-box{
    padding: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .input-first,.btn-first{
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 0 10px;
  }
  .btn-first{
    background-color: #69f;
    color: #fff;
    padding: 0 20px;
  }

</style>